<template>
    <span :class="`c-${currencyName}`">
      {{ price }}
    </span>
</template>

<script lang="ts" setup>
import {computed} from 'vue'
import {useStore} from '~/store/index.js'

const props = defineProps<{
  price: string | number,
  decimal?: number
}>()

// use store
const store = useStore()

const currencyName = computed(() => store.currency)
const currency = computed(() => store.rate)

const price = computed(() => {
  if (isNaN(+props.price) || isNaN(+props.price * currency.value)) {
    return 'N/A'
  }

  const formattedPrice = (+props.price / 1000) * currency.value
  const decimalPlaces = ['rub', 'krw', 'cny', 'jpy'].includes(currencyName.value)
      ? (formattedPrice < 0.1 ? 3 : formattedPrice < 1 ? 2 : 0)
      : (formattedPrice < 0.1 ? 3 : formattedPrice < 1 ? 2 : (props.decimal ?? 2))

  return new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  }).format(formattedPrice).replace(',', '.')
})

</script>


<style lang="scss" scoped>


p {
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--accent-on-accent-secondary);
  margin: 0;
  display: inline;

  span {
    margin-left: 0;

    &:before {
      margin-right: 0.3rem;
    }
  }
}
</style>
